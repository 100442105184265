"use client";

import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";

import ErrorContainer from "@/components/containers/ErrorContainer";
import FullScreenImageLayout from "@/components/layouts/FullScreenImageLayout";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <FullScreenImageLayout>
      <ErrorContainer error={error} reset={reset} />
    </FullScreenImageLayout>
  );
}
